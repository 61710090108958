var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "pb-8" },
        [
          _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-account")]),
          _vm._v(" Autenticação (2FA) "),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-switch", {
                    staticClass: "switch-tfa",
                    attrs: {
                      inset: "",
                      "hide-details": "",
                      label: "Autenticação de dois fatores",
                    },
                    model: {
                      value: _vm.user.twoFactorAuthenticationEnabled,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.user,
                          "twoFactorAuthenticationEnabled",
                          $$v
                        )
                      },
                      expression: "user.twoFactorAuthenticationEnabled",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticStyle: {
                    "padding-bottom": "10px",
                    "margin-top": "20px",
                    "text-align": "right",
                  },
                  attrs: { cols: "12", sm: "6" },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.user.twoFactorAuthenticationEnabled === true
                          ? "Ativado"
                          : "Desativado"
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "pt-4" }, [
            _c("p", [
              _vm._v(
                " Para proteger ainda mais a sua conta, enviaremos um código de verificação para o seu e-mail cadastrado em todas as suas tentativas de acesso ao Agnes. "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "btn-ag-primary",
              attrs: { loading: _vm.loadingSave, disabled: _vm.loadingSave },
              on: { click: _vm.save },
            },
            [_vm._v("Salvar")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }