<template>
  <v-card>
    <v-card-title class="pb-8">
      <v-icon left>mdi-account</v-icon>
      Autenticação (2FA)
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <v-switch
            v-model="user.twoFactorAuthenticationEnabled"
            inset
            hide-details
            label="Autenticação de dois fatores"
            class="switch-tfa"
          >
          </v-switch>
        </v-col>
        <v-col cols="12" sm="6" style="padding-bottom: 10px; margin-top: 20px; text-align: right">
          <span>{{ user.twoFactorAuthenticationEnabled === true ? 'Ativado' : 'Desativado' }}</span>
        </v-col>
      </v-row>
      <div class="pt-4">
        <p>
          Para proteger ainda mais a sua conta, enviaremos um código de verificação para o seu e-mail cadastrado em
          todas as suas tentativas de acesso ao Agnes.
        </p>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="btn-ag-primary" :loading="loadingSave" :disabled="loadingSave" @click="save">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UserService from '@/services/userService.js';
import { mapState, mapActions } from 'vuex';

import { eventBus } from '../../../../main.js';

export default {
  props: ['id'],
  data: () => ({
    valid: false,
    loadingSave: false,
    isAuthenticationTwoFactors: true,
    form: {
      enabled: null,
    },
  }),

  computed: {
    ...mapState('cadastrosGerenciais/usuario', {
      user: (state) => state.user,
      loading: (state) => state.loading,
    }),
  },

  async mounted() {
    this.userForm = this.user;
  },

  methods: {
    ...mapActions('notifications', ['wasLast3Passwords1']),
    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },

    close() {
      this.isEditMode = false;
      this.form = this.formEdit;
    },

    async save() {
      this.user.twoFactorAuthenticationEnabled == false
        ? UserService.disableTwofa(this.id)
        : UserService.enableTwofa(this.id);
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/design/variables.scss';

.switch-tfa >>> .v-input--switch__thumb.primary--text {
  color: $neutral-color-low-light !important;
}

.switch-tfa >>> .v-input--switch__track {
  opacity: 100 !important;
}
</style>
